<template>
  <v-card id="account-setting-card">
        <account-settings-account :user="user"></account-settings-account>
  </v-card>
</template>

<script>
import DeviceService from "@/services/DeviceService";
import AccountSettingsAccount from './AccountSettingsAccount.vue'

export default {
  data() {
    return {
      user: {}
    }
  },
  components: {
    AccountSettingsAccount,
  },
  beforeMount() {
    if (this.$route.params.id != 'new') {
      DeviceService.getUser(this.$route.params.id).then(async response => {
        this.user = response.data.data
        this.user.password = undefined
      })
    } else {
      this.user = {
        name: '',
        surname: '',
        email: '',
        active: true
      }
    }
  }
}
</script>
