<template>
  <div>
    <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
      <v-avatar
        color="#EDE7F6"
        size="120"
        class="me-6"
      >
        <span class="primary--text text-h3">{{ helpers.genAvatar(user.name, user.surname) }}</span>

      </v-avatar>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              autocomplete="false"
              v-model="user.name"
              label="Imię"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              :autocomplete="false"
              v-model="user.surname"
              label="Nazwisko"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="user.email"
              label="E-mail"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              :autocomplete="false"
              v-model="user.password"
              dense
              label="Hasło"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              :autocomplete="false"
              v-model="user.role"
              dense
              outlined
              label="Rola"
              item-value="value"
              item-text="text"
              :items="helpers.roles"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-checkbox
              v-model="user.active"
            label="Aktywny">
            </v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              @click="saveUser"
            >
              Zapisz
            </v-btn>
            <v-btn
              color="error"
              outlined
              class="mt-4"
              type="reset"
              @click="cancelEdit"
            >
              Anuluj
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
  <v-snackbar
    v-model="snackbar"
    :color="snackbarColor"
  >
    {{ snackbarContent }}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Zamknij
      </v-btn>
    </template>
    </v-snackbar>
  </div>
</template>

<script>
import {
  mdiAlertOutline, mdiCloudUploadOutline, mdiEyeOffOutline, mdiEyeOutline
} from '@mdi/js'
import helpers from '@/utils/helpers'
import UserService from '@/services/UserService'

export default {
  props: {
    user: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      helpers,
      snackbar: false,
      snackbarContent: '',
      snackbarColor: '',
      isPasswordVisible: false,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiEyeOffOutline,
        mdiEyeOutline
      }
    }
  },
  methods: {
    cancelEdit() {
      this.$router.push({name: 'users'})
    },
    setSnackBar(snackbarColor, snackbarContent) {
      this.snackbar = true
      this.snackbarColor = snackbarColor
      this.snackbarContent = snackbarContent
    },
    saveUser() {
      if (this.user.id) {
        UserService.updateUser(this.user).then(response => {
          console.log(response.data)
          this.setSnackBar('success', 'Zmiany zostały zapisane')
          this.$router.push({name: 'users'})
        }).catch(response => {
          console.log(response.data)
          this.setSnackBar('error', 'Błąd zapisu danych')
        })
      } else {
        this.user.username = this.user.name[0].toLowerCase() + this.user.surname.toLowerCase()
        UserService.addUser(this.user).then(response => {
          this.setSnackBar('success', 'Zmiany zostały zapisane')
          this.$router.push({name: 'users'})
        }).catch(response => {
          this.setSnackBar('error', 'Błąd zapisu danych')
        })
      }
    }
  }
}
</script>
