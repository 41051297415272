import axios from 'axios'
import helpers from "@/utils/helpers";
const baseURL = helpers.genBaseURL()

const apiClient = axios.create({
  baseURL: baseURL + '/api/v1',
  withCredentials: true,
  headers: {
    Authorization: 'Bearer ts_tok',
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  addUser(user) {
    return apiClient.post('/users', user)
  },
  updateUser(user) {
    return apiClient.put(`/users/${user.id}`, user)
  },
  recoverPassword(email) {
    return apiClient.post('/users/passwordReset', email)
  }
}
